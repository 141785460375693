<template>
  <div class="">
    <Hero />
    <div class="bg-19112E-70 py-24 mt-85px md:mt-148px">
      <div class="max-w-1218px ml-auto mr-auto px-5">
        <div class="text-center flex justify-center">
          <div class="flex-grow-0 flex-shrink relative">
            <h2
              class="text-primary-green font-semibold text-24px sm:text-32px leading-tight"
            >
              <span>MUSE DAO </span>
              <div class="inline-block relative">
                <img
                  class="absolute bottom-2/4 transform translate-y-2/4 z-10 block"
                  src="@/assets/images/icon/eclipse-green-about.png"
                  alt="eclipse green about"
                />
                <img
                  class="absolute bottom-2/4 transform translate-y-2/4 z-10 block w-full"
                  src="@/assets/images/icon/eclipse-dark-about.png"
                  alt="eclipse dark about"
                />
                <span class="inline-block relative z-20"> Vision?</span>
              </div>
            </h2>
          </div>
        </div>

        <div class="mx-auto max-w-511px text-center mt-8">
          <p class="text-body-black leading-1-5 text-base">
            The Muse DAO focuses on building decentralized applications to
            further the NFT space. Anyone can join the DAO by having enough
            support from its members.
          </p>
        </div>
      </div>
    </div>
    <!-- end about -->

    <!-- work -->
    <div
      class="mt-85px md:mt-139px bg-transparent py-0 relative overflow-x-hidden"
    >
      <div class="work-blur absolute top-2/4 -right-24 block"></div>

      <div class="max-w-1218px ml-auto mr-auto px-5">
        <div class="text-center mb-121px">
          <h2
            class="text-white font-semibold text-24px sm:text-32px leading-tight mb-8"
          >
            How To Get Involved?
          </h2>
          <p
            class="text-body-black max-w-695px text-center leading-1-7 mx-auto text-base sm:text-xl"
          >
            The community can propose updates and vote for updates to different
            protocols that get built under the DAO. Builders can create
            proposals to get funding and build for the MUSE DAO. The community,
            support of our existing developers and designers', shared
            experiences, and funds are the benefits of building for the MUSE
            DAO.
          </p>
        </div>

        <div
          class="flex flex-row flex-wrap lg:flex-nowrap justify-start md:justify-center"
        >
          <div
            class="flex-grow-0 flex-shrink w-full max-w-1/3 lg:max-w-158px text-center mb-10 lg:mb-0"
          >
            <img
              class="h-20 mx-auto"
              src="@/assets/images/icon//work-propose-vote.png"
              alt="work propose vote"
            />
            <h4 class="mt-39px font-semibold text-base text-white">
              Propose and vote on updates
            </h4>
          </div>

          <div class="flex-grow-0 flex-shrink w-1/3 lg:w-auto px-2">
            <img
              class="pt-10 mx-auto"
              src="@/assets/images/icon/work-line.png"
              alt="work line"
            />
          </div>

          <div
            class="flex-grow-0 flex-shrink w-full max-w-1/3 lg:max-w-96px text-center mb-10 lg:mb-0"
          >
            <img
              class="h-20 mx-auto"
              src="@/assets/images/icon//work-get-funding.png"
              alt="work propose vote"
            />
            <h4 class="mt-39px font-semibold text-base text-white">
              Get funding
            </h4>
          </div>

          <div
            class="flex-grow-0 flex-shrink w-1/3 lg:w-auto px-2 hidden lg:block"
          >
            <img
              class="pt-10 mx-auto"
              src="@/assets/images/icon/work-line.png"
              alt="work line"
            />
          </div>

          <div
            class="flex-grow-0 flex-shrink w-full max-w-1/3 lg:max-w-184px text-center mb-10 lg:mb-0"
          >
            <img
              class="h-20 mx-auto"
              src="@/assets/images/icon//work-muse-dao.png"
              alt="work propose vote"
            />
            <h4 class="mt-39px font-semibold text-base text-white">
              Build for the MUSE DAO
            </h4>
          </div>

          <div class="flex-grow-0 flex-shrink w-1/3 lg:w-auto px-2">
            <img
              class="pt-10 mx-auto"
              src="@/assets/images/icon/work-line.png"
              alt="work line"
            />
          </div>

          <div
            class="flex-grow-0 flex-shrink w-full max-w-1/3 lg:max-w-119px text-center mb-10 lg:mb-0"
          >
            <img
              class="h-20 mx-auto"
              src="@/assets/images/icon//work-get-support.png"
              alt="work propose vote"
            />
            <h4 class="mt-39px font-semibold text-base text-white">
              Get support
            </h4>
          </div>

          <div class="flex-grow-0 flex-shrink w-1/3 lg:w-auto px-2">
            <img
              class="pt-10 mx-auto"
              src="@/assets/images/icon/work-line.png"
              alt="work line"
            />
          </div>

          <div
            class="flex-grow-0 flex-shrink w-full max-w-1/3 lg:max-w-172px text-center mb-10 lg:mb-0"
          >
            <img
              class="h-20 mx-auto"
              src="@/assets/images/icon//work-share-experience.png"
              alt="work propose vote"
            />
            <h4 class="mt-39px font-semibold text-base text-white">
              Share experience and funds
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="relative mt-85px overflow-scroll scrollbar-hide md:mt-160px">
      <div class="work-blur absolute top-2/4 -left-24 block"></div>

      <div class="max-w-1218px ml-auto mr-auto px-5 relative">
        <div class="text-center max-w-647px w-full mx-auto">
          <img
            class="max-w-431px w-full mx-auto -mb-200px hidden"
            src="@/assets/images/icon/muse-green.png"
            alt="muse green"
          />
          <img
            class="max-w-431px w-full mx-auto -mb-200px block"
            src="@/assets/images/icon/muse-dark.png"
            alt="muse green"
          />

          <h2
            class="text-primary-green text-24px sm:text-45px font-semibold mb-30px md:mb-12 leading-tight"
          >
            <!-- <span class="text-transparent">How is the MUSE token</span> -->
            <span class="inline-block relative">
              <img
                class="absolute bottom-2/4 left-0 right-0 transform translate-y-2/4 hidden w-full"
                src="@/assets/images/icon/eclipse-green-about.png"
                alt="eclipse green about"
              />
              <img
                class="absolute bottom-2/4 left-0 right-0 transform translate-y-2/4 block w-full"
                src="@/assets/images/icon/eclipse-dark-about.png"
                alt="eclipse dark about"
              />
              <span class="relative lg:z-20">$MUSE </span>
              <span> Token</span>
            </span>
          </h2>

          <p class="text-body-black leading-1-5 text-base sm:text-xl">
            $MUSE is the governance token of the DAO. With $MUSE, you can decide
            the future of the DAO, what gets built and how to manage the DAO’s
            treasury.
          </p>
        </div>
      </div>
    </div>
    <Projects id="projects" />
    <!-- <Contributors /> -->
    <Faqs id="faq" />

    <Footer />
  </div>
</template>
<script>
import Faqs from "@/components/home/Faqs.vue";
import Footer from "@/components/Footer.vue";
// import Contributors from "../components/home/Contributors.vue";
import Projects from "../components/home/Projects.vue";
import Hero from "../components/home/Hero.vue";
export default {
  name: "About",
  components: { Faqs, Projects, Hero, Footer },
  data: function () {
    return {};
  },
  beforeMount: async function () {},
  methods: {},
  computed: {},
  mounted: async function () {},
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
</style>
