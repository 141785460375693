import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/css/index.css";

Vue.config.productionTip = false;

import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Notifications from "vue-notification";
import axios from "axios";
import VueAxios from "vue-axios";
import vueAwesomeCountdown from "vue-awesome-countdown";

Vue.use(Notifications);
Vue.use(VueAxios, axios);

// Those are global functions available for all components
Vue.mixin({
  methods: {},
});

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: {
        1: "wss://eth-mainnet.g.alchemy.com/v2/7o_A2UJVRVUs9JrNiN5Hnz0u-8QTItj_",
      },
    },
  },
};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  theme: "dark",
  providerOptions, // required
});

(async function () {
  Vue.prototype.$web3Modal = web3Modal;
  Vue.config.productionTip = false;
  Vue.use(vueAwesomeCountdown, "vac");

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
})();
