<template>
  <div class="">
    <!-- header -->
    <div
      class="max-w-1120px mx-auto text-center text-24px font-semibold mt-85px sm:mt-125px mb-8"
    >
      <h3 class="text-white">
        Add liquidity to the MUSE/ETH pair to earn MUSE
      </h3>
    </div>
    <!-- header -->
    <!-- stacking -->
    <div
      class="flex flex-col md:flex-row flex-nowrap md:items-stretch max-w-1120px mx-auto px-5"
    >
      <div
        class="flex-grow-0 flex-shrink max-w-full md:max-w-693px w-full pt-8 md:pr-5 md:pt-20 order-last md:order-first"
      >
        <!-- start block 1-->
        <div class="rounded-3xl bg-19112E py-4 px-4">
          <div class="mt-2 text-center text-md text-body-black">
            <p>
              $MUSE can be used for specific votes on the platform, or you can
              sell $MUSE on
              <a
                href="https://v2.info.uniswap.org/token/0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81"
                target="_blank"
                class="text-primary-green hover:underline hover:text-primary"
              >
                uniswap.
              </a>
            </p>

           <!-- <p class="mt-5">
              For additional projects to get farming rewards, go to
              <a
                href="https://nft20.io/farm"
                target="_blank"
                class="text-primary-green hover:underline hover:text-primary"
              >
                NFT20 farms.
              </a>
            </p> -->
          </div>
        </div>
        <!--end block 1-->

        <!-- start block 2-->
        <div class="rounded-3xl mt-8 bg-19112E">
          <div class="py-6">
            <div class="flex md:flex-row flex-col md: gap-8">
              <div class="flex flex-col items-center space-y-4 w-full">
                <h5
                  class="text-3xl text-center sm:text-22px text-body-black uppercase font-bold text-2xl mb-4"
                >
                  Wallet
                </h5>

                <div class="items-center pb-6">
                  <div
                    class="text-body-black text-lg ml-2 border rounded-lg px-4 py-2"
                  >
                    <Number decimals="18" :number="userLpBalance + ''" /> $LP
                  </div>
                </div>
                <div class="flex items-center pb-6">
                  <img
                    src="@/assets/images/muse-exhange-icon-55px.png"
                    class="w-7"
                  />

                  <div class="text-body-black text-lg ml-2">
                    <TokenBalance
                      :erc20="`0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81`"
                      :account="account"
                      symbol=""
                      decimals="18"
                    />
                  </div>
                </div>
                <div class="flex items-center pb-6">
                  <img src="@/assets/images/eth.png" class="w-7" />

                  <div class="text-body-black text-lg ml-2">
                    <Number decimals="18" :number="balance" />
                  </div>
                </div>
                <!--
                <div class="items-center pb-6 ml-2">
                  <button
                    class="bg-green text-center text-sm font-medium rounded-lg py-2 px-4 text-heading-black"
                    @click="stake"
                  >
                    <span v-if="!hasTokenApproval">Approve & Stake</span>
                    <span v-else>Stake</span>
                  </button>
                </div>
                -->
              </div>
              <div class="flex flex-col items-center space-y-4 w-full">
                <h5
                  class="text-3xl text-center sm:text-22px text-body-black uppercase font-bold text-2xl mb-4"
                >
                  Stake
                </h5>

                <div
                  class="text-body-black ml-2 items-center text-lg border rounded-lg px-4 py-2"
                >
                  <Number decimals="18" :number="staked + ''" /> $LP
                </div>

                <div class="items-center pb-6">
                  <button
                    @click="withdraw(true)"
                    class="bg-green text-center text-sm font-medium rounded-lg py-2 mt-5 px-4 ml-2 text-heading-black"
                  >
                    Withdraw Stake
                  </button>
                </div>
              </div>
              <!--<div class="flex flex-col items-center space-y-4 w-full">
                <h5
                  class="text-3xl text-center sm:text-22px text-body-black uppercase font-bold text-2xl mb-4"
                >
                  Rewards
                </h5>

                <div class="ml-2 items-center">
                  <div
                    class="text-body-black mr-2 text-lg border rounded-lg px-4 py-2"
                  >
                    <Number decimals="18" :number="pendingPoints + ''" /> MUSE
                  </div>
                </div>

                <div class="items-center">
                  <div
                    class="text-lg text-body-black border rounded-lg px-4 py-2 ml-2 mt-4"
                  >
                    ~ ${{ rewards }}
                  </div>
                </div>

                <div class="mr-2 items-center">
                  <button
                    @click="withdraw(fale)"
                    class="bg-green text-center text-sm font-medium rounded-lg py-2 mt-5 ml-2 px-4 text-heading-black"
                  >
                    Claim Rewards
                  </button>
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
      <!-- end block 2 -->
      <!-- start block 3 -->
      <div
        class="rounded-3xl bg-19112E px-5 py-6 mt-6 md:mt-11 md:h-full font-medium text-body-black"
      >
        <div class="flex px-10">
          <h2
            class="flex justify-center text-body-black items-center text-2xl uppercase font-bold w-full"
          >
            <img
              class="rounded-full h-20 w-20 mr-4 object-cover"
              src="@/assets/images/muse.svg"
              alt
            />
            {{ symbol }}/ETH
          </h2>
        </div>

        <div class="flex items-center justify-center">
          <a :href="uniswapPoolAddress" target="_blank" class="ml-2">
            <img src="@/assets/images/uniswap.svg" alt class="w-5"
          /></a>
          <a
            href="https://etherscan.io/token/0x20d2c17d1928ef4290bf17f922a10eaa2770bf43"
            target="_blank"
            class="ml-2"
          >
            <img src="@/assets/images/etherscan.png" alt class="w-5"
          /></a>
        </div>

        <div class="flex-row items-center mt-2 items-center">
          <div
            class="rounded-xl bg-21173c px-4 py-4 mt-4 mb-4 font-medium text-body-black"
          >
            <div class="flex flex-row text-center">
              <div class="text-lg w-full text-body-black">
                Pool rewards:
                <div class="text-white">
                  {{ apy }}%<span class="ml-2">APY</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="rounded-xl bg-21173c px-4 py-4 mb-4 font-medium text-body-black"
          >
            <div class="flex flex-row text-center">
              <div class="text-lg w-full text-body-black">
                Total value locked:
                <div>
                  <span class="text-white">
                    ${{ totalLiquidity.toLocaleString() }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="rounded-xl bg-21173c px-4 py-4 mb-4 font-medium text-body-black"
          >
            <div class="flex flex-row text-center">
              <div class="text-lg w-full text-body-black">
                Daily MUSE rewards:
                <div>
                  <span class="text-white"> {{ dailyRewards }} </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="rounded-xl bg-21173c px-4 py-4 font-medium text-body-black"
          >
            <div class="flex flex-row text-center">
              <div class="text-lg w-full text-body-black">
                Total staked LP tokens:
                <div>
                  <span class="text-white">
                    <Number decimals="18" :number="totalStaked + ''" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end block 3 -->
    </div>
    <!-- end stacking -->
  </div>
</template>

<script>
import Number from "@/components/Number.vue";
import NFT20Abi from "@/assets/abis/Pair";
import TokenBalance from "@/components/TokenBalance.vue";

import BigNumber from "bignumber.js";

export default {
  name: "FarmCard",
  components: { Number, TokenBalance },
  props: {
    expandAll: Boolean,
    symbol: String,
    address: String,
    poolid: String,
    dailyRewards: Number,
    warn: Boolean,
    newTag: Boolean,
    // img: String,
    // timeLeft: String,
    // price: String,
  },
  data() {
    return {
      expanded: true,
      hasTokenApproval: false,
      staked: 0,
      pendingPoints: 0,
      userLpBalance: -1,
      erc20Contract: null,
      wethContract: null,
      totalLiquidity: 0,
      totalStaked: 0,
      apy: 0,
      balance: "",
    };
  },
  methods: {
    fetchBalance: async function () {
      if (this.$store.state.web3 == null) {
        return;
      }
      let balance = await this.$store.state.web3.eth.getBalance(this.account);
      this.balance = balance;
      setTimeout(this.fetchBalance, 10000);
    },
    toggleCardState: async function () {
      this.expanded = !this.expanded;
    },

    init: async function () {
      if (this.userLpBalance != -1 || this.$store.state.pairCount == 0) {
        return;
      }

      this.wethContract = new this.$store.state.web3.eth.Contract(
        NFT20Abi,
        "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
      );

      let balance = await this.wethContract.methods
        .balanceOf(this.address)
        .call();

      console.log("weth contract bal", balance);

      balance = parseFloat(
        new BigNumber(balance).shiftedBy(-parseInt(18)).toFixed(3)
      );
      this.totalLiquidity = balance * 2 * this.$store.state.ethPrice;

      this.apy = (
        ((this.dailyRewards * this.$store.state.musePrice * 365) /
          this.totalLiquidity) *
        100
      ).toFixed(2);

      console.log("apy ", this.apy);

      this.erc20Contract = new this.$store.state.web3.eth.Contract(
        NFT20Abi,
        this.address
      );

      const totalStaked = await this.erc20Contract.methods
        .balanceOf(this.$store.state.contracts.MasterChef)
        .call();

      this.totalStaked = totalStaked;

      console.log(totalStaked);
      this.hasTokenApproval =
        (
          await this.erc20Contract.methods
            .allowance(
              this.$store.state.account,
              this.$store.state.contracts.MasterChef
            )
            .call()
        ).length >= 18;

      const staked = await this.$store.state.MasterChef.methods
        .userInfo(this.poolid, this.$store.state.account)
        .call();
      this.staked = staked.amount;

      console.log("staked amout", this.staked);

      this.pendingPoints = await this.$store.state.MasterChef.methods
        .pendingMuse(this.poolid, this.$store.state.account)
        .call();

      console.log(this.$store.state.musePrice);

      const userLpBalance = await this.erc20Contract.methods
        .balanceOf(this.$store.state.account)
        .call();
      this.userLpBalance = userLpBalance;
    },
    withdraw: async function (withdrawStake) {
      try {
        // if (!this.hasTokenApproval) {
        //   await this.approve();
        // }

        if (withdrawStake) {
          await this.$store.state.MasterChef.methods
            .emergencyWithdraw(this.poolid)
            .send({
              from: this.$store.state.account,
            })
            .on("transactionHash", (hash) => {
              console.log("hash of tx ", hash);
              // pass the hash to notify to track it
              this.$store.state.notify.hash(hash);
            });
        } else {
          await this.$store.state.MasterChef.methods
            .emergencyWithdraw(this.poolid)
            .send({
              from: this.$store.state.account,
            })
            .on("transactionHash", (hash) => {
              // pass the hash to notify to track it
              this.$store.state.notify.hash(hash);
            });
        }
      } catch (e) {
        console.log(e);
      }
    },
    stake: async function () {
      try {
        if (!this.hasTokenApproval) {
          await this.approve();
        }

        const userLpBalance = await this.erc20Contract.methods
          .balanceOf(this.$store.state.account)
          .call();
        await this.$store.state.MasterChef.methods
          .deposit(this.poolid, userLpBalance)
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
      } catch (e) {
        console.log(e);
      }
    },
    approve: async function () {
      let max =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      await this.erc20Contract.methods
        .approve(this.$store.state.contracts.MasterChef, max)
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });

      this.init();
    },
  },
  beforeMount: function () {
    this.fetchBalance();
  },
  mounted: async function () {
    this.init();
    this.fetchBalance();
  },
  watch: {
    isConnected: async function () {
      this.init();
    },
  },

  computed: {
    account: function () {
      return this.$store.state.account;
    },
    isConnected: function () {
      return this.$store.state.account;

      // return this.$store.state.pairCount > 0;
    },
    rewards: function () {
      return (
        this.pendingPoints * this.$store.state.musePrice
      ).toLocaleString();
    },
    uniswapPoolAddress: function () {
      return "https://v2.info.uniswap.org/pair/" + this.address;
    },
  },
};
</script>

<style scoped></style>
