<template>
  <div class="max-w-1218px ml-auto mr-auto px-5 pt-4 sm:pt-14">
    <div class="flex flex-row flex-wrap md:flex-nowrap items-center">
      <div class="flex-grow flex-shrink">
        <router-link to="/">
          <img src="@/assets/images/muse-logo.png" alt="logo" />
        </router-link>
      </div>

      <div
        class="block md:hidden flex-grow-0 flex-shrink relative"
        @click="navActive = !navActive"
      >
        <button id="btnHamburger">
          <img
            class="hidden"
            src="@/assets/images/icon/hamburger-black.svg"
            alt="hamburger menu"
          />
          <img
            class="block"
            src="@/assets/images/icon/hamburger-white.svg"
            alt="hamburger menu"
          />
        </button>
      </div>

      <div
        class="flex-grow md:flex-grow-0 flex-shrink relative w-full md:w-auto"
      >
        <ul
          id="navbarMenu"
          :class="{
            'nav-menu-nonactive': !navActive,
            'nav-menu-active': navActive,
          }"
          class="
            items-center
            duration-300
            ease-in-out
            z-50
            mt-4
            md:mt-0
            p-5
            md:p-0
            rounded-lg
            flex flex-col
            md:flex-row
            flex-nowrap
            absolute
            md:relative
            left-0
            right-0
            bg-light
            md:bg-transparent
            shadow-primary
            md:shadow-none
          "
        >
          <!-- nav item -->
          <li class="nav-item">
            <a href="/#projects" class="nav-link">Projects</a>
          </li>

          <!-- nav item -->
          <li class="nav-item">
            <a href="https://docs.musedao.io" target="_blank" class="nav-link"
              >Docs</a
            >
          </li>

          <!-- nav item -->
          <li class="nav-item">
            <a href="/#faq" class="nav-link">FAQ</a>
          </li>

          <!-- nav item -->
          <li class="nav-item">
            <router-link to="/stake" class="nav-link">Stake</router-link>
          </li>

          <!-- nav item -->
          <li class="nav-item">
            <router-link to="/farm" class="nav-link">Farm</router-link>
          </li>

          <!-- nav item -->
          <li class="nav-item">
            <a href="https://forum.musedao.io" target="_blank " class="nav-link"
              >Governance</a
            >
          </li>
          <li class="nav-item" v-if="!isConnected">
            <ConnectButton />
          </li>

          <li v-else class="nav-item">
            <button
              class="
                bg-green
                py-3
                px-5
                font-medium
                text-heading-black text-sm
                rounded-lg
              "
            >
              <div v-if="ensName">{{ ensName }}</div>
              <div v-else>
                {{
                  account
                    ? `${account.substring(0, 6)}...${account.substring(
                        account.length - 4,
                        account.length
                      )}`
                    : ""
                }}
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import ConnectButton from "./wallet/ConnectButton.vue";

export default {
  name: "Header",
  components: { ConnectButton },
  data: function () {
    return {
      showMenuMobile: false,
      modalOpened: false,
      navActive: false,
      // score: 0,
    };
  },
  props: {},
  asyncComputed: {},
  computed: {
    isConnected: function () {
      return this.$store.state.web3 != null;
    },
    account: function () {
      return this.$store.state.account;
    },
    ensName: function () {
      return this.$store.state.ensName.name;
    },
  },
  mounted: async function () {},
  methods: {
    connectWallet: async function () {
      this.$store.dispatch("connectWallet", this);
    },
    disconnectWallet: function () {
      this.$store.dispatch("disconnectWallet", this);
    },

    destroyed: async function () {
      // this.showMenuMobile = !this.showMenuMobile;
    },
  },
  beforeDestroy: async function () {
    // this.$store.commit("cancelWeb3");
  },
};
</script>

<style scoped></style>
