import Vue from "vue";
import VueRouter from "vue-router";

import About from "../views/About.vue";
import Stake from "../views/Stake.vue";
import Farm from "../views/Farm.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "About",
    component: About,
  },
  {
    path: "/farm",
    name: "Farm",
    component: Farm,
  },
  {
    path: "/stake",
    name: "Stake",
    component: Stake,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
