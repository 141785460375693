<template>
  <div>
    <div class="bg-dark py-70px mt-85px md:mt-121px">
      <div class="max-w-1240px mx-auto px-5">
        <img
          class="w-full relative z-10"
          src="@/assets/images/count-map.png"
          alt="map"
        />

        <div
          class="
            flex flex-row flex-wrap
            justify-center
            lg:flex-nowrap
            relative
            z-20
            -mt-10
            sm:-mt-28
          "
        >
          <!-- count item -->
          <div
            class="
              flex-grow-0 flex-shrink
              w-2/4
              lg:w-1/4
              pb-8
              lg:pb-0
              px-3
              sm:px-5
            "
          >
            <h3
              class="
                font-semibold
                text-28px
                sm:text-45px
                leading-tight
                text-white text-center
                mb-2
              "
            >
              4200
            </h3>
            <span class="text-body-black text-center block">DAO Members</span>
          </div>

          <!-- count item -->
          <div
            class="
              flex-grow-0 flex-shrink
              w-2/4
              lg:w-1/4
              pb-8
              lg:pb-0
              px-3
              sm:px-5
            "
          >
            <h3
              class="
                font-semibold
                text-28px
                sm:text-45px
                leading-tight
                text-white text-center
                mb-2
              "
            >
              {{
                Number(data.market_data.market_cap.usd / 1000000).toFixed(2)
              }}M
            </h3>
            <span class="text-body-black text-center block">Market Cap</span>
          </div>

          <!-- count item -->
          <div
            class="
              flex-grow-0 flex-shrink
              w-2/4
              lg:w-1/4
              pb-8
              lg:pb-0
              px-3
              sm:px-5
            "
          >
            <h3
              class="
                font-semibold
                text-28px
                sm:text-45px
                leading-tight
                text-white text-center
                mb-2
              "
            >
              {{ Number(data.market_data.total_supply / 1000).toFixed() }}k
            </h3>
            <span class="text-body-black text-center block"
              >Circulating Supply</span
            >
          </div>

          <!-- count item -->
          <div
            class="
              flex-grow-0 flex-shrink
              w-2/4
              lg:w-1/4
              pb-8
              lg:pb-0
              px-3
              sm:px-5
              hidden
            "
          >
            <h3
              class="
                font-semibold
                text-28px
                sm:text-45px
                leading-tight
                text-white text-center
                mb-2
              "
            >
              $1,2M
            </h3>
            <span class="text-body-black text-center block"
              >Circulating Supply</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="bg-dark pt-85px pb-110px md:py-110px">
      <div class="max-w-1240px mx-auto px-5">
        <h2
          class="
            text-white text-30px
            sm:text-60px
            font-semibold
            leading-tight
            text-center
          "
        >
          Join the community
        </h2>

        <div class="flex justify-center mt-5">
          <a href="https://discord.gg/kfByfux" target="_blank">
            <img
              class="w-100px"
              src="@/assets/images/icon/join-community.png"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Footer",
  props: {},
  data() {
    return {
      data: { market_data: { market_cap: "total_supply" } },
    };
  },
  mounted() {
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/muse-2?localization=true&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false"
      )
      .then((response) => (this.data = response.data));
  },
};
</script>
