<template>
  <div v-if="!isConnected" class="">
    <button
      v-on:click="connectWallet()"
      class="
        bg-green
        py-3
        px-5
        font-medium
        text-heading-black
        dark:text-heading-black
        text-sm
        rounded-lg
      "
    >
      Connect my wallet
    </button>
  </div>
</template>

<script>
export default {
  name: "ConnectWallet",
  data: function () {
    return {};
  },
  computed: {
    isConnected: function () {
      return this.$store.state.web3 != null;
    },
  },
  methods: {
    connectWallet: function () {
      this.$store.dispatch("connectWallet", this);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
