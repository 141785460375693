import Vue from "vue";
import Vuex from "vuex";

import Web3 from "web3";

import Contracts from "@/assets/contracts";
import Notify from "bnc-notify";

import axios from "axios";

import MuseStakerAbi from "@/assets/abis/MuseStaker";
import PairABI from "@/assets/abis/Pair";
import MasterChefABI from "@/assets/abis/MasterChef";

import ENS, { getEnsAddress } from "@ensdomains/ensjs";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    web3: null,
    notify: null,
    isLoaded: false,
    account: null,
    ensName: null,
    contracts: {},
    isLoading: false,
    network: process.env.NETWORK == null ? 0 : parseInt(process.env.NETWORK),
    correctNetwork: null,
    musePrice: null,
  },
  mutations: {
    disconnectWallet: async function (state) {
      let web3 = new Web3(
        new Web3.providers.WebsocketProvider(
          `wss://eth-mainnet.alchemyapi.io/v2/GBL4FjihpKI6R44D3cUPcR337AD8I2HN`
        )
      );
      state.web3 = web3;
      state.account = null;
    },
    setWeb3: async function (state, web3) {
      if (state.web3 != null && state.account != null) {
        //   // state.web3 = null;
        return;
      }


      const priceCall = await axios(
        `https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=ETH,MUSE`
      );

      let account = (await web3.eth.getAccounts())[0];
      if (account == null) {
        return;
      }

      // set ENS name
      const ens = new ENS({
        provider: window.ethereum,
        ensAddress: getEnsAddress("1"),
      });
      let ensName = await ens.getName(account);
      if (account == null) {
        return;
      }

      // set muse price
      console.log(priceCall.data)
      state.musePrice = 1/priceCall.data.MUSE;
      state.ethPrice = 1/priceCall.data.ETH;

      state.web3 = web3;
      state.account = account;
      state.ensName = ensName;
      console.log("ensName", state.ensName.name);
      console.log("account", state.account);
      state.contracts = Contracts;

      // Blocknative stuff

      state.notify = Notify({
        dappId: "6af111c7-b0b9-48f6-b61e-350f841b0612", // [String] The API key created by step one above
        networkId: 1,
        darkMode: true,
      });

      console.log("NEW ACCOUNT", state.account);
      if (state.isLoaded || state.isLoading) {
        return;
      }
      state.isLoading = true;

      console.log("set Web3");

      state.MuseStaker = new web3.eth.Contract(MuseStakerAbi, Contracts.sMUSE);

      state.isLoaded = true;

      state.UniEthMuseContract = new web3.eth.Contract(
        PairABI,
        Contracts.UniEthMuse
      );
      state.MasterChef = new web3.eth.Contract(
        MasterChefABI,
        Contracts.MasterChef
      );

      state.Pair = async (contract) => {
        return new web3.eth.Contract(PairABI, contract);
      };
    },
  },
  actions: {
    connectWallet: async function (context, vue) {
      console.log("connecting");

      context.state.web3 = null;
      const provider = await vue.$web3Modal.connect();
      // provider.clearCachedProvider();

      const web3 = new Web3(provider);
      context.commit("setWeb3", web3, vue);

      provider.on("accountsChanged", () => {
        context.dispatch("connectWallet", vue);
        console.log("CHANGED");
      });

      // Subscribe to chainId change
      provider.on("chainChanged", () => {
        console.log("CHAIN");

        context.dispatch("connectWallet", vue);
      });

      // Subscribe to provider disconnection
      provider.on("connect", () => {
        console.log("CONNECT");
      });

      // Subscribe to provider disconnection
      provider.on("disconnect", () => {
        // context.dispatch("connectWallet", vue);
      });
    },
    disconnectWallet: async function (context, vue) {
      await vue.$web3Modal.clearCachedProvider();
      context.commit("disconnectWallet");
    },
    startWeb3: async function (context, vue) {
      let web3 = new Web3(
        new Web3.providers.WebsocketProvider(
          `wss://eth-mainnet.alchemyapi.io/v2/GBL4FjihpKI6R44D3cUPcR337AD8I2HN`
        )
      );
      if (this.state.network == 1) {
        web3 = new Web3(
          new Web3.providers.WebsocketProvider(
            `wss://rpc-mainnet.maticvigil.com/ws` //TODO change with matic RPC
          )
        );
      }
      if (vue.$web3Modal.cachedProvider) {
        //This is case where someone already connected
        const provider = await vue.$web3Modal.connect();
        web3 = new Web3(provider);
      }
      let chainId = await web3.eth.getChainId();
      console.log(chainId);
      if (context.state.network == 1 && chainId != 137) {
        context.state.correctNetwork = "Matic network";
      }
      if (context.state.network == 0 && chainId != 1) {
        context.state.correctNetwork = "Ethereum Mainnet";
      }

      context.commit("setWeb3", web3, vue);
    },
  },
  getters: {},
  modules: {},
});
