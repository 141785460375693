<template>
  <div id="app">
    <Header />
    <router-view />
  </div>
</template>
    
<script>
import Header from "@/components/Header.vue";

export default {
  name: "App",
  components: { Header },
  data: function () {
    return {};
  },
  beforeMount: async function () {
    // this makes popup for web3modal, not so good

    // if (this.$web3Modal.providerController.cachedProvider != null) {
    //   console.log(
    //     "Connecting back to: ",
    //     this.$web3Modal.providerController.cachedProvider
    //   );
    //   this.$store.dispatch("connectWallet", this);
    // }

    // this is to start with web3 provider no wallet
    this.$store.dispatch("startWeb3", this);
  },
  computed: {
    isConnected: function () {
      return this.$store.state.web3 != null;
    },
    isCorrectNetwork: function () {
      return this.$store.state.correctNetwork;
    },
  },
  methods: {
    connectWallet: function () {
      this.$store.dispatch("connectWallet", this);
    },
  },
};
</script>


<style lang="scss">
</style>
