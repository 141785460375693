<template>
  <div class="">
    <div v-if="!isLoaded">
      <div
        class="
          max-w-1120px
          mx-auto
          px-5
          text-center text-24px
          font-semibold
          leading-tight
          mt-85px
          sm:mt-125px
          mb-12
          sm:mb-60px
          text-white
        "
      >
        Connect Your Wallet

        <div class="mt-24">
          <ConnectButton />
        </div>
      </div>
    </div>
    <div v-if="isConnected && isLoaded">
      <div
        v-if="isLoaded"
        class="flex flex-col space-y-4 justify-center items-center mt-2"
      >
        <FarmCard
          symbol="MUSE"
          address="0x20d2c17d1928ef4290bf17f922a10eaa2770bf43"
          poolid="0"
          dailyRewards="0"
        />
      </div>
    </div>

    <!-- footer -->
    <div
      class="
        mt-128
        pb-64
        max-w-1120px
        mx-auto
        px-5
        mt-85px
        sm:mt-170px
        flex flex-row flex-nowrap
        justify-center
        sm:justify-end
      "
    >
      <a
        href="https://discord.gg/kfByfux"
        class="
          flex flex-row flex-nowrap
          text-white text-base
          sm:text-lg
          font-semibold
          items-center
        "
        target="_blank"
      >
        <span class="flex-grow flex-shrink pr-6">Join the community</span>
        <span class="flex-grow-0 flex-shrink">
          <img src="@/assets/images/discord-gray-circle.png" class="w-60px" />
        </span>
      </a>
    </div>
  </div>
</template>

<style scoped>
::placeholder {
  color: white;
  opacity: 0.7;
}
</style>
<script>
import ConnectButton from "@/components/wallet/ConnectButton.vue";
import FarmCard from "@/components/FarmCard.vue";

export default {
  name: "Farm",
  components: { FarmCard, ConnectButton },
  data: function () {
    return {
      search: "",
      museStakerBug: false,
    };
  },
  methods: {},
  mounted: function () {},
  computed: {
    isLoaded: function () {
      return this.$store.state.isLoaded;
    },
    account: function () {
      return this.$store.state.account;
    },
    isConnected: function () {
      return this.$store.state.account;
      // return this.$store.state.pairCount > 0;
    },
  },
  watch: {
    isConnected: async function () {},
  },
};
</script>
