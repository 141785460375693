<template>
  <div>
    <div v-if="!isLoaded">
      <div
        class="
          max-w-1120px
          mx-auto
          px-5
          text-center text-24px
          font-semibold
          leading-tight
          mt-85px
          sm:mt-125px
          mb-12
          sm:mb-60px
          text-white
        "
      >
        Connect Your Wallet

        <div class="mt-24">
          <ConnectButton />
        </div>
      </div>
    </div>
    <div v-if="isConnected && isLoaded">
      <!-- stacking header -->
      <div
        class="
          max-w-1120px
          mx-auto
          px-5
          text-center text-24px
          font-semibold
          leading-tight
          mt-85px
          sm:mt-125px
          mb-12
          sm:mb-60px
        "
      >
        <h3 class="text-white">Single MUSE Staking</h3>
      </div>
      <!-- end stacking header -->

      <!-- stacking -->
      <div
        class="
          flex flex-col
          md:flex-row
          flex-nowrap
          md:items-stretch
          max-w-1120px
          mx-auto
          px-5
        "
      >
        <div
          class="
            flex-grow-0 flex-shrink
            max-w-full
            md:max-w-693px
            w-full
            pt-8
            md:pr-5 md:pt-20
            order-last
            md:order-first
          "
        >
          <!-- block -->
          <div
            class="rounded-3xl bg-19112E px-4 py-6 sm:p-11 md:mt-0 md:h-full"
          >
            <h5
              class="
                text-lg
                sm:text-22px
                font-semibold
                text-white
                mb-5
                sm:mb-30px
              "
            >
              Stake
            </h5>

            <div>
              <!-- input -->
              <div
                class="
                  bg-21173c
                  rounded-2xl
                  py-6
                  px-4
                  sm:px-10
                  flex flex-row flex-nowrap
                  items-center
                  relative
                "
              >
                <div
                  class="
                    flex-grow-0 flex-shrink flex flex-nowrap flex-row
                    items-center
                  "
                >
                  <img
                    src="@/assets/images/muse-exhange-icon.png"
                    class="w-38px"
                    id="input1Image"
                  />

                  <h5 class="text-base text-body-black ml-3" id="input1Title">
                    MUSE
                  </h5>
                </div>

                <input
                  id="input1Exchange"
                  type="input"
                  class="
                    input-number-stake
                    text-center
                    absolute
                    top-2/4
                    left-3/4
                    sm:left-2/4
                    transform
                    -translate-x-3/4
                    sm:-translate-x-2/4
                    -translate-y-2/4
                  "
                  v-model="muse"
                />
              </div>

              <!-- button changed to an image -->
              <div class="my-4 text-center">
                <center>
                  <img src="@/assets/images/change-icon.png" />
                </center>
              </div>

              <!-- input -->
              <div
                class="
                  bg-21173c
                  rounded-2xl
                  py-6
                  px-4
                  sm:px-10
                  flex flex-row flex-nowrap
                  items-center
                  relative
                "
              >
                <div
                  class="
                    flex-grow-0 flex-shrink flex flex-nowrap flex-row
                    items-center
                  "
                >
                  <img
                    src="@/assets/images/smuse-exhange-icon.png"
                    class="w-38px"
                    id="input2Image"
                  />

                  <h5 class="text-base text-body-black ml-3" id="input2Title">
                    sMUSE
                  </h5>
                </div>

                <input
                  id="input2Exchange"
                  type="input"
                  class="
                    input-number-stake
                    text-center
                    absolute
                    top-2/4
                    left-3/4
                    sm:left-2/4
                    transform
                    -translate-x-3/4
                    sm:-translate-x-2/4
                    -translate-y-2/4
                  "
                  v-model="smuse"
                />
              </div>
            </div>

            <div class="mt-8 sm:mt-70px max-w-483px mx-auto">
              <p
                class="
                  text-center text-sm
                  sm:text-base
                  leading-1-4
                  text-body-black
                "
              >
                Stake your MUSE and gain sMUSE. No impermanent loss, no loss of
                governance rights. Continuously compounding. All staked MUSE are
                subject to a 10 day lock-up period!
              </p>

              <p
                class="
                  text-center text-sm
                  sm:text-base
                  leading-1-4
                  text-body-black
                  mt-5
                "
              >
                sMUSE automatically earns fees from the diferent protocol
                earnings.
              </p>
            </div>

            <div class="text-center sm:text-right mt-10 sm:mt-16">
              <button
                class="
                  bg-green
                  py-4
                  px-10
                  text-lg
                  font-medium
                  text-heading-black
                  rounded-xl
                "
                @click="stake"
              >
                Stake
              </button>
            </div>
          </div>
          <!-- end block -->
        </div>

        <div class="flex-grow-0 flex-shrink md:max-w-407px w-full">
          <!-- block -->
          <div
            class="
              rounded-xl
              bg-19112E
              py-2
              px-5
              sm:px-11
              flex flex-row flex-nowrap
            "
          >
            <div class="flex-grow-0 flex-shrink">
              <img
                src="@/assets/images/monkey-logo.png"
                class="w-7 sm:w-35px"
              />
            </div>

            <div
              class="
                flex-grow-0 flex-shrink flex flex-row flex-nowrap
                items-center
                pl-4
                sm:pl-7
              "
            >
              <div class="flex-grow-0 flex-shrink">
                <img
                  src="@/assets/images/smuse-icon.png"
                  class="w-4 sm:w-19px"
                />
              </div>
              <h5 class="text-body-black font-medium text-sm md:text-base pl-2">
                1 sMUSE =
              </h5>
            </div>

            <div
              class="
                flex-grow-0 flex-shrink flex flex-row flex-nowrap
                items-center
                pl-2
              "
            >
              <div class="flex-grow-0 flex-shrink">
                <img
                  src="@/assets/images/muse-icon.png"
                  class="w-5 sm:w-19px hidden"
                />
                <img
                  src="@/assets/images/muse-icon-dark.png"
                  class="w-5 sm:w-19px block"
                />
              </div>
              <h5 class="text-body-black font-medium text-sm md:text-base pl-2">
                {{ smuseValueMuse.toFixed(3) }} MUSE
              </h5>
            </div>
          </div>
          <!-- end block -->

          <!-- block -->
          <div class="rounded-3xl bg-19112E px-4 py-6 sm:p-11 mt-8">
            <h5
              class="text-lg sm:text-22px font-semibold text-white mb-4 sm:mb-5"
            >
              Staking parameters
            </h5>

            <div class="flex flex-row flex-nowrap items-center">
              <div class="flex-grow-0 flex-shrink">
                <img
                  src="@/assets/images/smuse-exhange-icon-55px.png"
                  class="w-55px"
                />
              </div>

              <div class="flex-grow flex-shrink pl-3">
                <h5 class="text-body-black font-medium leading-1-5">
                  1 sMUSE = ${{ smuseValueUsd.toFixed(2) }}
                </h5>
              </div>
            </div>

            <h5
              class="
                text-lg
                sm:text-22px
                font-semibold
                text-white
                mb-4
                sm:mb-5
                mt-6
                sm:mt-9
              "
            >
              Balance
            </h5>

            <div
              class="
                flex flex-row flex-nowrap
                items-center
                pb-6
                border-b border-gray-200
              "
            >
              <div class="flex-grow-0 flex-shrink">
                <img
                  src="@/assets/images/muse-exhange-icon-55px.png"
                  class="w-55px"
                />
              </div>

              <div class="flex-grow flex-shrink pl-3">
                <h5 class="text-body-black font-medium leading-1-5">MUSE</h5>
              </div>

              <div class="flex-grow-0 flex-shrink">
                <h5 class="text-body-black font-medium leading-1-5">
                  <TokenBalance
                    :erc20="`0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81`"
                    :account="account"
                    symbol="MUSE"
                    decimals="18"
                  />
                </h5>
              </div>
            </div>

            <h5
              class="
                text-lg
                sm:text-22px
                font-semibold
                text-white
                mb-4
                sm:mb-5
                mt-6
                sm:mt-9
              "
            >
              Staked
            </h5>

            <div class="flex flex-row flex-nowrap items-center">
              <div class="flex-grow-0 flex-shrink">
                <img
                  src="@/assets/images/smuse-exhange-icon-55px.png"
                  class="w-55px"
                />
              </div>

              <div class="flex-grow flex-shrink pl-3">
                <h5 class="text-body-black font-medium leading-1-5">sMUSE</h5>
              </div>

              <div class="flex-grow-0 flex-shrink">
                <h5 class="text-body-black font-medium leading-1-5">
                  <Number decimals="18" :number="userInfo.balance + ''" />
                </h5>
              </div>
            </div>

            <div
              class="
                flex flex-row flex-nowrap
                items-center
                bg-21173c
                rounded-xl
                py-2
                sm:py-3
                px-3
                sm:px-5
                mt-6
                sm:mt-12
              "
            >
              <div class="flex-grow flex-shrink pl-3">
                <h5 class="text-body-black leading-1-5 text-xs sm:text-sm">
                  Approximate staking APR
                </h5>
              </div>

              <div class="flex-grow-0 flex-shrink">
                <h5
                  class="
                    text-body-black
                    font-medium
                    leading-1-5
                    text-xs
                    sm:text-sm
                  "
                >
                  22.4562%
                </h5>
              </div>
            </div>

            <div
              v-if="userInfo.timelock > 0"
              class="
                flex flex-row flex-nowrap
                items-center
                bg-21173c
                rounded-xl
                py-2
                sm:py-3
                px-3
                sm:px-5
                mt-3
              "
            >
              <div class="flex-grow flex-shrink pl-3">
                <h5 class="text-body-black leading-1-5 text-xs sm:text-sm">
                  To withdraw:
                </h5>
              </div>

              <div class="flex-grow-0 flex-shrink">
                <countdown
                  v-if="userInfo.timelock > 0"
                  :end-time="userInfo.timelock * 1000"
                >
                  <template v-slot:process="mine">
                    <ul class="flex flex-row flex-nowrap">
                      <li class="flex-grow-0 flex-shrink">
                        <span
                          class="
                            block
                            text-center text-lg
                            font-semibold
                            text-body-black
                          "
                          id="countDays"
                          >{{ mine.timeObj.d }}</span
                        >
                        <span class="block text-center text-xs text-body-black"
                          >days</span
                        >
                      </li>
                      <li class="flex-grow-0 flex-shrink px-2">
                        <span
                          class="
                            block
                            text-center text-lg
                            font-semibold
                            text-body-black
                          "
                          >:</span
                        >
                      </li>
                      <li class="flex-grow-0 flex-shrink">
                        <span
                          class="
                            block
                            text-center text-lg
                            font-semibold
                            text-body-black
                          "
                          id="countHours"
                          >{{ mine.timeObj.h }}</span
                        >
                        <span class="block text-center text-xs text-body-black"
                          >hours</span
                        >
                      </li>
                      <li class="flex-grow-0 flex-shrink px-2">
                        <span
                          class="
                            block
                            text-center text-lg
                            font-semibold
                            text-body-black
                          "
                          >:</span
                        >
                      </li>
                      <li class="flex-grow-0 flex-shrink">
                        <span
                          class="
                            block
                            text-center text-lg
                            font-semibold
                            text-body-black
                          "
                          id="countMins"
                          >{{ mine.timeObj.m }}</span
                        >
                        <span class="block text-center text-xs text-body-black"
                          >mins</span
                        >
                      </li>
                      <li class="flex-grow-0 flex-shrink px-2">
                        <span
                          class="
                            block
                            text-center text-lg
                            font-semibold
                            text-body-black
                          "
                          >:</span
                        >
                      </li>
                      <li class="flex-grow-0 flex-shrink">
                        <span
                          class="
                            block
                            text-center text-lg
                            font-semibold
                            text-body-black
                          "
                          id="countMins"
                          >{{ mine.timeObj.s }}</span
                        >
                        <span class="block text-center text-xs text-body-black"
                          >secs</span
                        >
                      </li>
                    </ul>
                  </template>
                  <template v-slot:finish>
                    <span class="text-body-black">Now!</span>
                  </template>
                </countdown>
              </div>
            </div>

            <div class="text-center sm:text-right mt-3" id="unstakeWrapId">
              <button
                v-if="
                  userInfo.timelock > 0 &&
                  Math.floor(Date.now() / 1000) > userInfo.timelock &&
                  Math.floor(Date.now() / 1000) - userInfo.timelock < 172800
                "
                class="
                  bg-green
                  py-3
                  px-5
                  font-medium
                  text-heading-black text-sm
                  rounded-lg
                "
                @click="withdraw"
              >
                Unstake MUSE
              </button>
              <button
                v-else-if="
                  (userInfo.timelock == 0 && userInfo.balance > 0) ||
                  Math.floor(Date.now() / 1000) - userInfo.timelock > 172800
                "
                class="
                  bg-green
                  py-3
                  px-5
                  font-medium
                  text-heading-black text-sm
                  rounded-lg
                "
                @click="startWaiting"
              >
                Start Unstake Period
              </button>
            </div>
          </div>
          <!-- end block -->
        </div>
      </div>
      <!-- end stacking -->
    </div>
    <!-- footer -->
    <div
      class="
        mt-128
        pb-64
        max-w-1120px
        mx-auto
        px-5
        mt-85px
        sm:mt-170px
        flex flex-row flex-nowrap
        justify-center
        sm:justify-end
      "
    >
      <a
        href="https://discord.gg/kfByfux"
        class="
          flex flex-row flex-nowrap
          text-white text-base
          sm:text-lg
          font-semibold
          items-center
        "
        target="_blank"
      >
        <span class="flex-grow flex-shrink pr-6">Join the community</span>
        <span class="flex-grow-0 flex-shrink">
          <img src="@/assets/images/discord-gray-circle.png" class="w-60px" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import ConnectButton from "@/components/wallet/ConnectButton.vue";

import TokenBalance from "@/components/TokenBalance.vue";
import Number from "@/components/Number.vue";
import ERC20Abi from "@/assets/abis/ERC20";
// import BigNumber from "bignumber.js";
export default {
  name: "Stake",
  components: { TokenBalance, ConnectButton, Number },
  props: {
    expandAll: Boolean,
    symbol: String,
    address: String,
    poolid: String,
    dailyRewards: Number,
    warn: Boolean,
    newTag: Boolean,
    canWithdraw: Boolean,
  },
  data() {
    return {
      isLoaded: false,
      expanded: false,
      hasTokenApproval: false,
      staked: 0,
      // claimable: 0,
      userLpBalance: -1,
      erc20Contract: null,
      wethContract: null,
      totalStaked: 0,
      userInfo: null,
      apy: 0,
      usersShares: 0,
      smuseValueUsd: 0,
      smuseValueMuse: 0,
      museToStake: 0,
    };
  },
  methods: {
    // toggleCardState: async function () {
    //   this.expanded = !this.expanded;
    // },
    init: async function () {
      if (this.$store.state.account == null) {
        return;
      }
      this.erc20Contract = new this.$store.state.web3.eth.Contract(
        ERC20Abi,
        this.$store.state.contracts.MUSE
      );

      const totalStaked = await this.erc20Contract.methods
        .balanceOf(this.$store.state.contracts.sMUSE)
        .call();

      this.totalStaked = totalStaked;

      this.hasTokenApproval =
        (
          await this.erc20Contract.methods
            .allowance(
              this.$store.state.account,
              this.$store.state.contracts.sMUSE
            )
            .call()
        ).length >= 18;
      // const userLpBalance = await this.erc20Contract.methods
      //   .balanceOf(this.$store.state.account)
      //   .call();
      // this.userLpBalance = userLpBalance;
      this.userInfo = await await this.$store.state.MuseStaker.methods
        .userInfo(this.$store.state.account)
        .call();

      console.log("userInfo.timelock", this.userInfo.timelock);

      // this.userInfo.timelock = this.userInfo.timelock - 24 * 60 * 60; //to test
      // this.userInfo.timelock = "0"; //to test

      console.log("user Info ", this.userInfo);
      this.userShares = await await this.$store.state.MuseStaker.methods
        .balanceOf(this.$store.state.account)
        .call();
      // this.percentageShares =
      //   (this.userShares / this.userInfo.globalShares) * 100;
      const museStakerSupply =
        await await await this.$store.state.MuseStaker.methods
          .totalSupply()
          .call();

      this.smuseValueUsd =
        (totalStaked / museStakerSupply) * this.$store.state.musePrice;

      this.smuseValueMuse = totalStaked / museStakerSupply;
      // this.apy = (
      //   ((this.dailyRewards * this.$store.state.musePrice * 365) /
      //     this.totalStaked) *
      //   100
      // ).toFixed(2);
      this.isLoaded = true;
    },
    startWaiting: async function () {
      await this.$store.state.MuseStaker.methods
        .startUnstake()
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
      this.init();
      alert(
        "Your waiting period will end in 10 days, and you'll be able to withdraw your stake + rewards within 2 days after the waiting period ends."
      );

      location.reload();
    },
    withdraw: async function () {
      try {
        // if (!this.hasTokenApproval) {
        //   await this.approve();
        // }
        await this.$store.state.MuseStaker.methods
          .unstake(this.userInfo.balance)
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
        this.init();
      } catch (e) {
        console.log(e);
      }
    },
    stake: async function () {
      try {
        if (!this.hasTokenApproval) {
          await this.approve();
        }
        // const userLpBalance = await this.erc20Contract.methods
        //   .balanceOf(this.$store.state.account)
        //   .call();

        await this.$store.state.MuseStaker.methods
          .enter(
            this.$store.state.web3.utils.toWei(
              this.museToStake.toString(),
              "ether"
            )
          )

          // .enter(BigNumber())
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
        this.init();
      } catch (e) {
        console.log(e);
      }
    },
    approve: async function () {
      let max =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      await this.erc20Contract.methods
        .approve(this.$store.state.contracts.sMUSE, max)
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
      this.init();
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.querySelector("#wrapper");

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },
  mounted: async function () {
    this.init();
    // this.toggleBodyClass("addClass", "bg-gray");
    // this.toggleBodyClass("removeClass", "bg-dark");
  },
  destroyed() {
    // this.toggleBodyClass("removeClass", "bg-gray");
    // this.toggleBodyClass("addClass", "bg-dark"); //for light theme later
  },
  watch: {
    isConnected: async function () {
      this.init();
    },
  },
  computed: {
    account: function () {
      return this.$store.state.account;
    },
    museTosMUSE: function () {
      return (this.museToStake / this.smuseValueMuse).toFixed(3);
    },
    isConnected: function () {
      return this.$store.state.account;
      // return this.$store.state.pairCount > 0;
    },
    //smuse / muse converter
    smuse: {
      get() {
        return this.museToStake / this.smuseValueMuse;
      },
      set(value) {
        this.museToStake = (value || 0) * this.smuseValueMuse;
      },
    },
    muse: {
      get() {
        return this.museToStake;
      },
      set(value) {
        this.museToStake = +value;
      },
    },
  },
};
</script>

<style scoped>
.not-expanded .card-content {
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  height: 0;
  padding: 0;
}
.card-content {
  transition: all 150ms ease;
}
.chevron {
  transform: rotate(180deg);
  transition: transform 150ms ease-out;
}
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black;*/ /* If you want dots under the hoverable text */
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 18px;
  border-radius: 6px;
  /*padding: 2rem;*/
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
